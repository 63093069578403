<template>
    <div>
        <h2>{{ $t("invoice.titre_article_course") }}</h2>
        <div class="box">

            <DateRangeV2
                :start.sync="start_date"
                :end.sync="end_date"
                :dateSelect="true"
                :rawColumnNames="rawColumnNames"
                :config_table_hrefs="config_table_hrefs"
                periodLabel="mois"
                @submit="loadArticlesCourseFormatted(start_date, end_date)"
            />

            <CustomTable
                ref="articlesCourseTable"   
                id_table="articles_courses"
                :items="actes_course"
                :busy.sync="table_busy"
                primaryKey="actes_id"
                :hide_if_empty="true"
                :hrefsRoutes="config_table_hrefs"
            >
            </CustomTable>
        </div>
    </div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import Accounting from "@/mixins/Accounting.js"
    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Config from "@/mixins/Config.js"
    import Tiers from "@/mixins/Tiers.js"

    export default {
        name: "articleCourse",
        mixins: [Config, TableAction, Invoice, Navigation, Accounting, Tiers],
        props: {},
        data () {
            return {
                busy: false,
                processing: false,
                table_busy: true,
                start_date: null,
                end_date:  new Date(),
                actes_course: [],
                rawColumnNames: ['horse.horse_nom'],
                config_table_hrefs: {
                    'horse.horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    }
                },
                events_tab: {
                    'TableAction::goToValideArticleCourse': (params) => {
					    this.valideCourseAValider(params)
				    },
                    'TableAction::goToAbandonFacturationAValider': this.goToAbandonAValider,
                },
            }
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                if(this.getConfig('startDateArticlesCourse')) {
                    this.start_date = new Date(this.getConfig('startDateAValider'))
                }
                if(this.getConfig('endDateArticlesCourse')) {
                    this.end_date = new Date(this.getConfig('endDateAValider'))
                }
            },

            async loadArticlesCourseFormatted(start_date, end_date) {
                this.table_busy = true
                if(start_date)
                {
                    start_date = start_date.toDateInputValue()
                }
                if(end_date)
                {
                    end_date = end_date.toDateInputValue()
                }

                const actes_course = await this.loadArticlesCourse(start_date, end_date)
                this.actes_course = await this.formatArticlesCourse(actes_course)

				this.table_busy = false
            },

			async formatArticlesCourse(actes_course) {
                let formatted_actes = []
                for (const acte of actes_course){
                    for (const reponse of acte.reponses){
                        if (reponse.question.question_libelle == "RESULTAT : gain" && reponse.reponse_data[0] && reponse.reponse_data[0] != "0"){
                            acte.gains = reponse.reponse_data[0]
                        }
                    }
                    if(!acte.gains) continue
                    let temp = acte
                    temp.jockey = 0
                    temp.entraineur = 0
                    for (const tier of acte.horse.tiers_horse){
                        if(tier.tiers_horse_part.length != 0){
                            temp.jockey += tier.tiers_horse_part[tier.tiers_horse_part.length-1].tiershorsepart_jockey ?? 0
                            temp.entraineur += tier.tiers_horse_part[tier.tiers_horse_part.length-1].tiershorsepart_entraineur ?? 0
                        }
                    }
                    formatted_actes.push(temp)
                }
				return formatted_actes
			},

            async valideCourseAValider(params){
                if(this.busy == true) return
                this.busy = true
                const accounting_plan = await this.loadAccountingPlans()
                for(const param of params){
                    let canBeValidated = false
                    let paramsToSend = {};
                    for(const plan of accounting_plan){
                        if (plan.accountingplan_jockey_article || plan.accountingplan_trainer_article){
                            const articles = await this.loadArticlesByEntity(plan.accountingplan_tiers)
                            for(const tier of param.horse.tiers_horse){
                                if(tier.tiershorse_fonction != "PROPRIO"){
                                    continue
                                }

                                if(plan.accountingplan_jockey_article && tier.tiers_horse_part[tier.tiers_horse_part.length-1].tiershorsepart_jockey > 0 && (plan.tiers.tiers_id != tier.tiershorse_tiers)){
                                    const jockey_article = articles.filter(element => element.articles_id == plan.accountingplan_jockey_article)
                                    paramsToSend.details = [{
                                        author_id : plan.tiers.tiers_id,
                                        tiers_id : tier.tiershorse_tiers,
                                        ht_initial : param.gains * 100,
                                        htunit : param.gains * param.jockey / 100,
                                        qte : 1,
                                        vat :  jockey_article[0].articles_vat,
                                        label : jockey_article[0].articles_label,
                                        description : jockey_article[0].articles_commentaire,
                                        horse_id : param.horse.horse_id,
                                        actes_id : param.actes_id,
                                        articles_id : jockey_article[0].articles_id,
                                        vat_code :  await this.getVatCode(plan.tiers.tiers_id, plan.accountingplan_tiers),
                                        export_vat: 0,
                                        tiershorseparttype: 4,
                                        tiershorsepartpart: param.jockey
                                    }]
                                    canBeValidated = await this.createInvoiceDetails(paramsToSend)
                                }

                                if(plan.accountingplan_trainer_article && tier.tiers_horse_part[tier.tiers_horse_part.length-1].tiershorsepart_entraineur > 0  && (plan.tiers.tiers_id != tier.tiershorse_tiers)){
                                    const trainer_article = articles.filter(element => element.articles_id == plan.accountingplan_trainer_article)
                                    paramsToSend.details = [{
                                        author_id : plan.tiers.tiers_id,
                                        tiers_id : tier.tiershorse_tiers,
                                        ht_initial : param.gains * 100,
                                        htunit : param.gains * param.entraineur / 100,
                                        qte : 1,
                                        vat :  trainer_article[0].articles_vat,
                                        label : trainer_article[0].articles_label,
                                        description : trainer_article[0].articles_commentaire,
                                        horse_id : param.horse.horse_id,
                                        actes_id : param.actes_id,
                                        articles_id : trainer_article[0].articles_id,
                                        vat_code :  await this.getVatCode(plan.tiers.tiers_id, plan.accountingplan_tiers),
                                        export_vat: 0,
                                        tiershorseparttype: 4,
                                        tiershorsepartpart: param.entraineur
                                    }]
                                    canBeValidated = await this.createInvoiceDetails(paramsToSend)
                                }
                            }   
                        }
                    }
                    if(canBeValidated) {
                        // await this.validateCourseActe(param)
                        this.$router.push({name: 'invoiceBons'})
                    }
                    else {
                        this.failureToast();
                        this.$refs["articlesCourseTable"].unselectAll()
                    }
                    this.busy = false
                }

            },
            async goToAbandonAValider(horse_actes) {
                if(!this.processing)
                {
                    this.processing = true
                    let paramsToSend = {};
                    paramsToSend['details'] = []
                    paramsToSend['pensions'] = []

                    if(horse_actes) {
                        for(let i = 0; i < horse_actes.length; i++) {
                            paramsToSend.details.push(
                                horse_actes[i].actes_id,
                            )
                        }
                        paramsToSend.details = paramsToSend.details.toString()
                    }

                    if(paramsToSend) {
                        await this.abandonAValider(paramsToSend)
                    }
                    this.$refs.articlesCourseTable.unselectAll()
                    this.loadArticlesCourseFormatted(this.start_date, this.end_date)

                    EventBus.$emit("TableAction::stopSpin")
                    this.processing = false
                    
                }
            },
            
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
        }
    }
</script>
